import {
  Constants,
  PageType,
  SignInType,
  ORG_SIGNIN_TYPES,
  EMAIL_SIGNIN_TYPES,
  EXT_SIGNIN_TYPES,
  useLocalStorage,
  VclApiProps,
  HTTP_METHODS,
  VclApiGetType,
  useVclApi,
  IAuthResult,
  IPageMetadata,
} from 'vcl-common';
import { useState, useCallback, useEffect, useMemo } from 'react';
import Signin from '../signIn/signin';
import { loginRequest } from '../../utils/msalConfig';
import { AxiosResponse } from 'axios';

interface IAuthenticatorProps {
  pageMetadata: IPageMetadata;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

function Authenticator(props: IAuthenticatorProps) {
  const pageMetadata = props.pageMetadata;
  const isAuthenticated = props.isAuthenticated;
  const setIsAuthenticated = props.setIsAuthenticated;

  const [isOrgAuthenticated, setIsOrgAuthenticated] = useState(false);
  const [isForbidden, setIsForbidden] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailTriggered, setEmailTriggered] = useState(false);
  const { setAuthResult, getIsAdminOfCurrentPage } = useLocalStorage();

  const apiAuthOrgProps = useMemo<VclApiProps>(() => {
    let apiUrl: string = '';
    switch (pageMetadata?.pageType) {
      case PageType.Webcast:
        apiUrl = Constants.routes.api.authOrgWebcast;
        break;
      case PageType.Channel:
        apiUrl = Constants.routes.api.authOrgChannel;
        break;
    }

    if (apiUrl === '') {
      console.error('Invalid page type');
    }

    return {
      apiUrl: apiUrl,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
      params: { url: window.location.href, id: pageMetadata?.entityId },
    };
  }, [pageMetadata?.entityId, pageMetadata?.pageType]);
  const { callApi: callAuthOrg } = useVclApi<IAuthResult>(apiAuthOrgProps);

  const apiAuthEmailProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.authEmailWebcastRegister,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi: callAuthEmail } = useVclApi<IAuthResult>(apiAuthEmailProps);

  const apiAuthPinProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.authPinWebcast,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi: callAuthPin } = useVclApi<IAuthResult>(apiAuthPinProps);

  const apiAuthEmailValidateProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.authEmailWebcastValidate,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi: callAuthEmailValidate } = useVclApi<IAuthResult>(
    apiAuthEmailValidateProps,
  );

  const isAdmin = getIsAdminOfCurrentPage();

  const authOrg = useCallback(async () => {
    const response = (await callAuthOrg()) as AxiosResponse<IAuthResult>;

    if (!response.data?.success) {
      setIsForbidden(true);
    } else {
      setIsOrgAuthenticated(true);
      setAuthResult(response.data);
      setIsAuthenticated(true);
    }
  }, [callAuthOrg, setAuthResult, setIsAuthenticated]);

  const authEmail = useCallback(
    async (event: any) => {
      event.preventDefault();

      // Ensure this function only runs for relevant signInType values
      if (
        !(pageMetadata && EMAIL_SIGNIN_TYPES.includes(pageMetadata?.signInType))
      ) {
        console.error('Operation aborted: Incorrect signInType for authEmail.');
        return; // Early exit if signInType is not applicable
      }

      const formData = new FormData(event.currentTarget);
      const name = formData.get('fullname') as string; // Assuming input's name attribute is 'name'
      const email = formData.get('email') as string; // Assuming input's name attribute is 'email'
      const company = formData.get('companyName') as string; // Assuming input's name attribute is 'company'

      if (!name || !email || !company) {
        console.error('Required form fields are missing.');
        setEmailError(true);
        return; // Early exit if essential fields are missing
      }

      const params = {
        url: window.location.href,
        id: pageMetadata?.entityId,
        name: name,
        email: email,
        company: company,
      };
      const response = (await callAuthEmail(
        undefined,
        undefined,
        params,
        undefined,
      )) as AxiosResponse<IAuthResult>;

      if (!response.data || !response.data.success) {
        setIsForbidden(true);
        setEmailError(true);
        setEmailTriggered(false);
      } else {
        setIsForbidden(false);
        setEmailError(false);
        setEmailTriggered(true);
      }
    },
    [pageMetadata, callAuthEmail],
  );

  const authPin = useCallback(
    async (event: any) => {
      event.preventDefault();

      // Directly extracting the PIN value from the form element
      const pin = event.target[0].value;
      if (!pin) {
        console.error('PIN is required but was not provided.');
        return; // Early exit if pin is not provided
      }

      const params = {
        url: window.location.href,
        id: pageMetadata?.entityId,
        pin: pin,
      };
      const response = (await callAuthPin(
        undefined,
        undefined,
        params,
        undefined,
      )) as AxiosResponse<IAuthResult>;

      if (!response.data || !response.data.success) {
        setIsForbidden(true);
      } else {
        setAuthResult(response.data);
        setIsAuthenticated(true);
      }
    },
    [callAuthPin, setAuthResult, setIsAuthenticated, pageMetadata?.entityId],
  );

  const authEmailValidate = useCallback(async () => {
    if (
      (pageMetadata?.signInType === SignInType.EmailPin && !emailVerified) ||
      pageMetadata?.signInType === SignInType.Email
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      const encryptedToken = queryParams.get('t');
      if (!encryptedToken) return; // Early exit if no token

      const decodedToken = decodeURIComponent(encryptedToken);
      if (!decodedToken) return; // Early exit if token can't be decoded

      const params = {
        url: window.location.href,
        id: pageMetadata?.entityId,
        token: decodedToken,
      };
      const response = (await callAuthEmailValidate(
        undefined,
        undefined,
        params,
        undefined,
      )) as AxiosResponse<IAuthResult>;

      if (!response.data || !response.data.success) {
        setIsForbidden(true);
      } else {
        setAuthResult(response.data);
        if (pageMetadata?.signInType === SignInType.EmailPin) {
          setEmailVerified(true);
        }
        setIsAuthenticated(true);
      }
    }
  }, [
    callAuthEmailValidate,
    emailVerified,
    pageMetadata?.entityId,
    pageMetadata?.signInType,
    setAuthResult,
    setIsAuthenticated,
  ]);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) return;

      if (
        (pageMetadata && ORG_SIGNIN_TYPES.includes(pageMetadata?.signInType)) ||
        isAdmin
      ) {
        await authOrg();
      } else if (
        pageMetadata &&
        EMAIL_SIGNIN_TYPES.includes(pageMetadata?.signInType)
      ) {
        await authEmailValidate();
      }
    })();
  }, [pageMetadata, authEmailValidate, authOrg, isAdmin, isAuthenticated]);

  return (
    <>
      {((pageMetadata && EXT_SIGNIN_TYPES.includes(pageMetadata?.signInType)) ||
        (pageMetadata?.signInType === SignInType.OrgPin &&
          isOrgAuthenticated)) &&
        !isAdmin && (
          <Signin
            signInType={pageMetadata?.signInType}
            emailSignInCallback={authEmail}
            pinSignInCallback={authPin}
            isForbidden={isForbidden}
            emailVerified={emailVerified}
            emailError={emailError}
            emailTriggered={emailTriggered}
          />
        )}
      {pageMetadata?.signInType === SignInType.OrgPin &&
        !isOrgAuthenticated &&
        isForbidden && (
          <div>
            <h2>Forbidden</h2>
            <p>You are not allowed to access this page.</p>
          </div>
        )}
    </>
  );
}

export default Authenticator;
