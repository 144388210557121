import { BsGoogle } from 'react-icons/bs';
import './saveTheDate.css';
import { IPageMetadata } from 'vcl-common';

interface ISaveTheDateProps {
  pageMetadata: IPageMetadata;
}

function SaveTheDate(props: ISaveTheDateProps) {
  const pageMetadata = props.pageMetadata;
  const webcast = props.pageMetadata?.urlInfo?.webcast;

  const googleText = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${webcast?.titleCombined}&dates=${webcast?.startTime}/${webcast?.endTime}&details=Watch+the+webcast+here:+${pageMetadata?.urlInfo?.url}&location=live.volvocars.com&sprop&sprop=name:`;

  return (
    <div className="saveTheDateContainer">
      <div className="saveTheDateIcon">
        <div>
          <a
            href={`https://accounts.google.com/v3/signin/identifier?dsh=S671200281%3A1671688386289255&continue=${googleText}&followup=${googleText}&osid=1&passive=1209600&service=cl&flowName=GlifWebSignIn&flowEntry=ServiceLogin&ifkv=AeAAQh6YQY-4LZM0KCP7tUzN5p63Zo4oTZlhBlho55onR5vnxM1Jxh82aMLkVKyDnNWwuglPtVSewQ`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsGoogle />
          </a>
          <h4>Google</h4>
        </div>

        <div>
          <div className="atcb">
            {`{
            "name":"${webcast?.titleCombined}", "description":"${webcast?.description}", "startDate":"${webcast?.startTime}",
            "endDate":"${webcast?.endTime}", "startTime":"${webcast?.startTime}", "endTime":"${webcast?.endTime}",
            "location":"${webcast?.location.title}", "label":"", "options":[
            "Apple" ], "timeZone":"Europe/Berlin",
            "iCalFileName":"Volvo-meeting"
            }`}
          </div>
          <h4>Apple</h4>
        </div>

        <div>
          <div className="atcb">
            {`{
            "name":"${webcast?.titleCombined}", "description":"${webcast?.description}", "startDate":"${webcast?.startTime}",
            "endDate":"${webcast?.endTime}", "startTime":"${webcast?.startTime}", "endTime":"${webcast?.endTime}",
            "location":"${webcast?.location.title}", "label":"", "options":[
            "Outlook.com|ok" ], "timeZone":"Europe/Berlin",
            "iCalFileName":"Volvo-meeting"
            }`}
          </div>
          <h4>Outlook</h4>
        </div>
      </div>
    </div>
  );
}

export default SaveTheDate;
