import {
  IWebcast,
  Constants,
  IFeedFilter,
  useLocalStorage,
  VclApiProps,
  HTTP_METHODS,
  VclApiGetType,
  useVclApi,
} from 'vcl-common';
import { loginRequest } from '../utils/msalConfig';
import { useMemo } from 'react';
import { IBatchResponse } from '../../../vcl.common/src/interfaces';
import { AxiosResponse } from 'axios';

export function useFeed() {
  const { getApiKey } = useLocalStorage();
  const apiGetProps = useMemo<VclApiProps>(() => {
    return {
      apiUrl: Constants.routes.api.webcastFeed,
      method: HTTP_METHODS.GET,
      getType: VclApiGetType.Item,
      useMsalAuthorization: true,
      loginRequest: loginRequest,
    };
  }, []);
  const { callApi } = useVclApi<IBatchResponse<IWebcast>>(apiGetProps);

  const apiKey = getApiKey();

  const getFeed = async (url: string, params: any) => {
    try {
      const response = (await callApi(
        undefined,
        url,
        params,
        undefined,
      )) as AxiosResponse<IBatchResponse<IWebcast>>;
      return {
        items: response?.data?.items,
        totalItems: response?.data?.properties?.totalItemCount,
      };
    } catch (error) {
      return {
        items: [],
        totalItems: 0,
      };
    }
  };

  const fetchFeed = async (
    feedType: string,
    organizationId: number,
    status: string,
    webcastId?: number,
    channelId?: number,
    page: number = 1,
    size: number = 4,
  ) => {
    let filter: IFeedFilter = {
      webcastId: webcastId,
      organizationId: organizationId,
      status: status,
      channelId: channelId,
      page: page,
      size: size,
    };
    const filterStr = JSON.stringify(filter);
    let params: any = { filter: filterStr };
    if (apiKey) {
      params = { ...params, apiKey: apiKey };
    }
    switch (feedType) {
      case 'webcast':
        return await getFeed(Constants.routes.api.webcastFeed, params);
      case 'channel':
        return await getFeed(Constants.routes.api.channelFeed, params);
      case 'latest':
        return await getFeed(Constants.routes.api.recommendedFeed, params);
      default:
        return {
          items: [],
          totalItems: 0,
        };
    }
  };

  return fetchFeed;
}
