import { useState, useRef } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillTwitterSquare,
  AiOutlineMail,
} from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './share.css';
import { IPageMetadata } from 'vcl-common';

interface IShareProps {
  pageMetadata: IPageMetadata;
}

function Share(props: IShareProps) {
  const webcast = props.pageMetadata?.urlInfo?.webcast;

  const [showEmailLink, setShowEmailLink] = useState(false);
  const showEmail = () => {
    setShowEmailLink(!showEmailLink);
  };
  const textAreaRef = useRef(null);
  function copyToClipboard() {
    const inputElement: any = document.getElementById('input-text');
    inputElement.select();
    document.execCommand('copy');
  }

  const url = `${window.location.protocol}//${window.location.hostname}${webcast?.relativeUrl}`;

  return (
    <div className="shareContainer">
      <div className="shareIcon">
        <a
          href={webcast?.shareContent.FacebookUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <AiFillFacebook />
            <h4>Facebook</h4>
          </div>
        </a>
        <a
          href={webcast?.shareContent.LinkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <AiFillLinkedin />
            <h4>Linkedin</h4>
          </div>
        </a>
        <a
          href={webcast?.shareContent.TwitterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <AiFillTwitterSquare />
            <h4>Twitter</h4>
          </div>
        </a>
        <div onClick={showEmail}>
          <AiOutlineMail />
          <h4>Send link</h4>
        </div>
      </div>
      {showEmailLink && (
        <div className="share-textarea">
          <InputGroup>
            <Form.Control
              id="input-text"
              as="textarea"
              rows={1}
              ref={textAreaRef}
              value={url}
            />
            <Button
              variant="btn"
              className="share-btn"
              id="button-addon2"
              onClick={copyToClipboard}
            >
              COPY
            </Button>
          </InputGroup>
        </div>
      )}
    </div>
  );
}

export default Share;
