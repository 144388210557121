// import React, { useContext, useEffect, useRef } from 'react';
// import {
//   IWebcast,
//   VclApi,
//   WebcastContext,
//   ViewingState,
//   PageMetadataContext,
// } from 'vcl-common';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from 'react-chartjs-2';
// import isoCountries from 'i18n-iso-countries';
// import en from 'i18n-iso-countries/langs/en.json';
// import './flagIcons.css';
// import './statistics.css';
// import { TableClient, AzureSASCredential } from '@azure/data-tables';
// import { toZonedTime } from 'date-fns-tz';
// import { isBefore } from 'date-fns/isBefore';

import { IPageMetadata } from 'vcl-common';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Legend,
// );

interface IStatisticsProps {
  pageMetadata: IPageMetadata;
}

const Statistics = (props: IStatisticsProps) => {
  // const pageMetadata = useContext(PageMetadataContext);
  // const globalSettings = pageMetadata?.globalSettings;
  // const webcast = useContext<IWebcast | undefined>(WebcastContext);

  // const showStatistics =
  //   webcast != null &&
  //   (webcast.viewingState <= 1 || webcast.statsMinimal != null); // later add the condition to check if statistics are available for a webcast?.

  // const [OnlineViewers, setOnlineViewers] = React.useState<number>(0);
  // const [totalViews, setTotalViews] = React.useState<number>(0);
  // const [topCountries, setTopCountries] = React.useState<any>([]);
  // const [topCities, setTopCities] = React.useState<any>([]);
  // const [viewsOverTime, setViewsOverTime] = React.useState<any>(null);
  // const [isLoading, setIsLoading] = React.useState<boolean>(true);

  // const statsInterval = globalSettings?.organization?.configuration.filter(
  //   (config: any) => config.key === 'StatsTimeInterval',
  // );
  // const timeinterval =
  //   statsInterval && statsInterval.length > 0
  //     ? Number(statsInterval[0].value)
  //     : 10000;
  // const intervalRef = useRef<any>(null);

  // const endDate = toZonedTime(new Date(webcast?.endTime ?? ''), 'UTC');

  // const [liveStats, setLiveStats] = React.useState<any>(null);

  // const vclApi = useRef<any>(null);

  // const sas = process.env['REACT_APP_SAS_TOKEN'] as string;
  // const onlineViewersStorageHostName = process.env[
  //   'REACT_APP_ONLINE_VIEWERS_STORAGE_HOST_NAME'
  // ] as string;
  // const onlineViewersTableName = process.env[
  //   'REACT_APP_ONLINE_VIEWERS_TABLE_NAME'
  // ] as string;

  // useEffect(() => {
  //   if (webcast?.viewingState === ViewingState.Live) {
  //     if (!vclApi.current) {
  //       vclApi.current = new VclApi();
  //     }

  //     console.log(toZonedTime(new Date(), 'UTC'));
  //     console.log(endDate);
  //     console.log(isBefore(toZonedTime(new Date(), 'UTC'), endDate));
  //     if (isBefore(toZonedTime(new Date(), 'UTC'), endDate)) {
  //       intervalRef.current = setInterval(async () => {
  //         setIsLoading(true);
  //         try {
  //           const onlineViewersTableClient = new TableClient(
  //             onlineViewersStorageHostName,
  //             onlineViewersTableName,
  //             new AzureSASCredential(sas),
  //           );
  //           const entity = await onlineViewersTableClient.getEntity(
  //             webcast?.accessKey,
  //             'LiveStatsMinimal',
  //           );
  //           if (
  //             entity &&
  //             entity.Statistics !== undefined &&
  //             entity.Statistics !== null
  //           ) {
  //             const liveStats: any = JSON.parse(entity.Statistics as string);
  //             if (
  //               liveStats.OnlineViewers !== undefined &&
  //               liveStats.OnlineViewers !== null &&
  //               liveStats.OnlineViewers >= 0
  //             ) {
  //               setLiveStats(liveStats);
  //               setIsLoading(false);
  //             }
  //           } else {
  //             console.log(
  //               'statistics',
  //               '[COUNTDOWN] Online viewers entity does not match the required criteria',
  //               true,
  //               1,
  //             );
  //             setIsLoading(false);
  //           }
  //         } catch (error) {
  //           console.log('statistics', JSON.stringify(error), true, 1);
  //           setIsLoading(false);
  //         }
  //       }, timeinterval);
  //     } else {
  //       clearInterval(intervalRef.current);
  //     }
  //   }
  //   return () => {
  //     if (intervalRef && intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, [
  //   endDate,
  //   onlineViewersStorageHostName,
  //   onlineViewersTableName,
  //   sas,
  //   showStatistics,
  //   timeinterval,
  //   vclApi,
  //   webcast,
  // ]);

  // // Top countries
  // isoCountries.registerLocale(en);

  // useEffect(() => {
  //   if (showStatistics) {
  //     let statsMinimalData = null;
  //     if (webcast?.viewingState === ViewingState.Live) {
  //       statsMinimalData = liveStats;
  //     } else {
  //       statsMinimalData = webcast?.statsMinimal
  //         ? JSON.parse(webcast?.statsMinimal)
  //         : null;
  //       if (isLoading) {
  //         setIsLoading(false);
  //       }
  //     }
  //     if (statsMinimalData === null) {
  //       return;
  //     }
  //     setOnlineViewers(statsMinimalData.OnlineViewers);
  //     setTotalViews(statsMinimalData.TotalViewCount);
  //     setTopCountries(statsMinimalData.TopCountries);
  //     setTopCities(statsMinimalData.TopCities);
  //     setViewsOverTime(statsMinimalData.ViewsOverTime);
  //     setIsLoading(false);
  //   }
  // }, [webcast, showStatistics, liveStats, isLoading]);

  // const roundUpToNearest = (num: number) => {
  //   const digits = Math.floor(Math.log10(num)) + 1;
  //   let nearest;

  //   if (digits <= 2) {
  //     nearest = 10;
  //   } else if (digits <= 3) {
  //     nearest = 100;
  //   } else {
  //     nearest = 10 ** (digits - 1);
  //   }

  //   return Math.ceil(num / nearest) * nearest;
  // };

  // const renderViewsOverTime = () => {
  //   const borderColor = '#3d5881';
  //   const fontColor = '#000000';
  //   const fontSize = 14;
  //   const fontFamily = 'Volvo Novum Light';
  //   const viewTimeTicksMinutes = '5';

  //   // Add a zero value to the beginning of the views over time object
  //   // and increment the following values to make sure the chart starts at 0
  //   const keyValuePairs = [
  //     { 0: 0 },
  //     ...Object.keys(viewsOverTime).map((interval) => {
  //       const newTick = parseInt(interval) + parseInt(viewTimeTicksMinutes);
  //       const newEntity = { [newTick]: viewsOverTime[interval] };
  //       return newEntity;
  //     }),
  //   ];
  //   const ViewsOverTime = Object.assign({}, ...keyValuePairs);

  //   const labels = Object.keys(ViewsOverTime).map((interval) => {
  //     const hours = Math.floor((ViewsOverTime[interval] as number) / 60);
  //     const minutes = (ViewsOverTime[interval] as number) % 60;
  //     const formattedMinutes =
  //       minutes < 10 ? `0${minutes}` : minutes.toString();
  //     return `${hours}:${formattedMinutes}`;
  //   });
  //   const values = Object.values(ViewsOverTime);
  //   const keyValueArray = Object.entries(values);

  //   // Step 2: Sort the array based on the values
  //   keyValueArray.sort((a, b) => (b[1] as number) - (a[1] as number)); // Sort in ascending order based on values

  //   const maxYValue = roundUpToNearest(keyValueArray[0][1] as number);

  //   const options: any = {
  //     responsive: true,
  //     maintainAspectRatio: false,
  //     plugins: {
  //       legend: {
  //         display: false,
  //       },
  //     },
  //     scales: {
  //       x: {
  //         title: {
  //           text: 'Views over time (hour:minute)',
  //           display: true,
  //           font: {
  //             size: fontSize,
  //             family: fontFamily,
  //           },
  //         },
  //         ticks: {
  //           maxTicksLimit: 5,
  //           font: {
  //             size: fontSize,
  //             family: fontFamily,
  //           },
  //         },
  //         grid: {
  //           display: false,
  //         },
  //       },
  //       y: {
  //         ticks: {
  //           maxTicksLimit: 5,
  //           font: {
  //             size: fontSize,
  //             family: fontFamily,
  //           },
  //         },
  //         min: 0,
  //         max: maxYValue,
  //         grid: {
  //           display: false,
  //         },
  //       },
  //     },
  //     borderColor: borderColor,
  //     color: fontColor,
  //   };

  //   const _data = {
  //     labels: labels,
  //     datasets: [
  //       {
  //         data: values,
  //         tension: 0.3,
  //       },
  //     ],
  //   };
  //   return <Line options={options} data={_data} />;
  // };

  // const getCountryJsx = (country: string, noOfViews: string) => {
  //   const name = isoCountries.getName(country, 'en');
  //   return (
  //     <div className="vl-statistics-item" key={`${country}`}>
  //       <span className={`fi fi-${country}`}></span>
  //       <span>{name}</span>
  //       <span>{noOfViews.toLocaleString()}</span>
  //     </div>
  //   );
  // };

  // const getCityJsx = (city: string, noOfViews: string) => {
  //   const country = city.substring(0, city.indexOf('|')).toLowerCase();
  //   const cityName = city.substring(city.indexOf('|') + 1, city.length);
  //   return (
  //     <div className="vl-statistics-item" key={`${cityName}`}>
  //       <span className={`fi fi-${country}`}></span>
  //       <span>{cityName}</span>
  //       <span>{noOfViews.toLocaleString()}</span>
  //     </div>
  //   );
  // };

  return (
    <div>TBD</div>
    // <div className="vl-statistics">
    //   {!showStatistics ? (
    //     <div className="vl-statistics-unavailable">
    //       There are no statistics available for this webcast?.
    //     </div>
    //   ) : (
    //     <>
    //       {isLoading ? (
    //         <></>
    //       ) : (
    //         <>
    //           {viewsOverTime && (
    //             <div className="vl-views-over-time-container">
    //               {renderViewsOverTime()}
    //             </div>
    //           )}
    //           <div className="vl-statistics-flex-container">
    //             <div className="vl-statistics-flex-item">
    //               <div className="vl-statistics-box">
    //                 <div className="vl-statistics-item vl-statistics-title">
    //                   {webcast?.viewingState === ViewingState.Live
    //                     ? 'Watching now'
    //                     : 'Total views'}
    //                 </div>
    //                 <div className="vl-statistics-views">
    //                   {webcast?.viewingState === ViewingState.Live
    //                     ? OnlineViewers
    //                     : totalViews}
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="vl-statistics-flex-item">
    //               <div className="vl-statistics-item vl-statistics-title">
    //                 Top countries
    //               </div>
    //               <div className="vl-statistics-list">
    //                 {topCountries.map((country: any) => {
    //                   return getCountryJsx(country.Name, country.NoOfViews);
    //                 })}
    //               </div>
    //             </div>
    //             <div className="vl-statistics-flex-item">
    //               <div className="vl-statistics-item vl-statistics-title">
    //                 Top cities
    //               </div>
    //               <div className="vl-statistics-list">
    //                 {topCities.map((city: any) => {
    //                   return getCityJsx(city.Name, city.NoOfViews);
    //                 })}
    //               </div>
    //             </div>
    //           </div>
    //         </>
    //       )}
    //     </>
    //   )}
    // </div>
  );
};

export default Statistics;
