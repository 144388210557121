import { useEffect, useState } from 'react';
import usePaginatedData from './usePaginatedData';
import ThumbnailCards from './thumbnailCards';
import Pagination from '../pagination/pagination';
import { IWebcast, IPageMetadata } from 'vcl-common';

interface ICarouselProps {
  pageMetadata: IPageMetadata;
  headerTitle: string;
  fetchItems: (page: number) => Promise<{
    items: IWebcast[];
    totalItems: number;
  }>;
  items?: IWebcast[];
  isFromChannel?: boolean;
}

const Carousel = (props: ICarouselProps) => {
  const pageMetadata = props.pageMetadata;
  const [rollupNoOfThumbnails, setRollupNoOfThumbnails] = useState(4);
  const [items, totalItems, currentPage, setCurrentPage, , error] =
    usePaginatedData<IWebcast>(props.fetchItems, rollupNoOfThumbnails);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / rollupNoOfThumbnails),
  );

  useEffect(() => {
    if (pageMetadata?.globalSettings?.organization?.configuration) {
      const _feedSize =
        pageMetadata?.globalSettings?.organization?.configuration.filter(
          (config) => config.key === 'RollupNoOfThumbnails',
        );
      const _fs = _feedSize.length > 0 ? Number(_feedSize[0].value) : 4;
      setRollupNoOfThumbnails(_fs);
    }
  }, [pageMetadata]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / rollupNoOfThumbnails));
  }, [totalItems, rollupNoOfThumbnails]);

  if (totalItems === 0 && (!props.items || props.items.length === 0)) {
    return <></>;
  }

  const itemsToRender =
    props.items && props.items.length > 0 ? props.items : items;

  return (
    <div>
      {error && <p>{error}</p>}
      {!error && (
        <div className="thumbnailContainer">
          <h2
            className={`thumbnailMainHeading ${
              props.isFromChannel ? 'fromChannelHeading' : ''
            }`}
          >
            {props.headerTitle}
          </h2>
          {<ThumbnailCards items={itemsToRender} />}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
          ></Pagination>
        </div>
      )}
    </div>
  );
};

export default Carousel;
