import { useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import './adminmenu.css';
import { IPageMetadata } from 'vcl-common';
import { useLocation } from 'react-router-dom';

interface IAdminMenuProps {
  pageMetadata: IPageMetadata;
  isFutureWebcast: boolean;
}

function Adminmenu(props: IAdminMenuProps) {
  const webcast = props.pageMetadata?.urlInfo?.webcast;
  const navigate = useLocation();
  const viewType = new URLSearchParams(navigate.search).get('viewType');
  const isCountDown =
    (props.isFutureWebcast === true && viewType === null) ||
    viewType === 'countDown';

  const textAreaRef = useRef<any>(null);
  function copyToClipboard(e: any) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  }

  return (
    <div className="adminmenuContainer">
      <div className="adminmenu-item" key="webcastSettings">
        <a
          href={`${process.env['REACT_APP_ADMINWEB_URL'] as string}${
            webcast?.adminRelativeUrl
          }`}
        >
          Webcast settings
        </a>
      </div>
      {!isCountDown ? (
        <div className="adminmenu-item" key={'viewCountdown'}>
          <a href={`${webcast?.relativeUrl}?viewType=countDown`}>
            View countdown{' '}
          </a>
        </div>
      ) : (
        <div className="adminmenu-item" key={'viewWebcast'}>
          <a href={`${webcast?.relativeUrl}?viewType=webcast`}>View webcast </a>
        </div>
      )}

      {webcast?.allowEmbed && (
        <div className="adminmenu-last" key={'embedWebcast'}>
          <div className="adminmenu-lastitem">
            <a href="!#">Embed webcast</a>
          </div>

          <div className="adminmenu-textarea">
            <InputGroup>
              <Form.Control
                as="textarea"
                rows={1}
                ref={textAreaRef}
                defaultValue={webcast?.shareContent.EmbedHtml}
              />
              <Button
                variant="btn"
                className="adminmenu-btn"
                id="button-addon2"
                onClick={copyToClipboard}
              >
                COPY
              </Button>
            </InputGroup>
          </div>
        </div>
      )}
    </div>
  );
}

export default Adminmenu;
